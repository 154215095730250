import React, { Component } from "react";
import FormStyle from "./FormStyle";

class ContactFour extends Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title mb-3 text-center">
                            <span className="subtitle">Let's Say Hi</span>
                            <h2 className="title">Contact Me</h2>
                            <p className="description">Let's Build Something Together.</p>
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="rn-address text-center p-3 border-0 "style={{ background: '#091020 ', color:'white', boxShadow:'0  0 20px rgba(0,0,9, .05)', }}>
                            <div className="icon mb-2 ">
                                <svg
                                    stroke="currentColor"
                                    fill="none"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M3 18v-6a9 9 0 0 1 18 0v6"></path>
                                    <path d="M21 19a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3zM3 19a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H3z"></path>
                                </svg>
                            </div>
                            <div className="inner">
                                <h4 className="title">Contact Phone Number</h4>
                                <p><a href="tel:+447411526504" style={{color:'white'}}>+44 7411 526504</a></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" >
                        <div className="rn-address text-center p-3 border-0" style={{ background: '#091020 ', color:'white'}}>
                            <div className="icon mb-2">
                                <svg
                                    stroke="currentColor"
                                    fill="none"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                                    <polyline points="22,6 12,13 2,6"></polyline>
                                </svg>
                            </div>
                            <div className="inner">
                                <h4 className="title">Email Address</h4>
                                <p><a href="mailto:contact@devmubeen.com" style={{color:'white'}}>contact@devmubeen.com</a></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" >
                        <div className="rn-address text-center p-3 border-0" style={{ background: '#091020 ', color:'white', }}>
                            <div className="icon mb-2">
                                <svg
                                    stroke="currentColor"
                                    fill="none"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect x="5" y="2" width="14" height="20" rx="2" ry="2"></rect>
                                    <line x1="12" y1="18" x2="12" y2="18"></line>
                                </svg>
                            </div>
                            <div className="inner">
                                <h4 className="title">WhatsApp</h4>
                                <p><a target="_blank" rel="noopener noreferrer" href="https://wa.me/+447411526504" style={{color:'white'}}>+44 7411 526504</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContactFour;
