import React, { Component } from "react";
import { Link } from 'react-router-dom';
const Portfolio_image = <img src="/assets/images/portfolio/healthwire.png" alt="https://apps.apple.com/us/app/healthwire-medicine-doctors/id1460789804" />;
const Portfolio_image2 = <img src="/assets/images/portfolio/askpdf.png" alt="https://apps.apple.com/us/app/chat-pdf-askpdf-chatpdf/id6462723046" />;
const Portfolio_image3 = <img src="/assets/images/portfolio/hcloud.png" alt="https://apps.apple.com/pk/app/hcloud-for-doctors/id1483037323" />;
const Portfolio_image4 = <img src="/assets/images/portfolio/blueberry.png" alt="https://apps.apple.com/us/app/blueberry-business-payments/id1568228823" />;
const Portfolio_image5 = <img src="/assets/images/portfolio/theDogHood.png" alt="https://apps.apple.com/us/app/thedoghood/id1547568850" />;
const Portfolio_image6 = <img src="/assets/images/portfolio/soldlive.png" alt="https://apps.apple.com/za/app/soldlive-overlays/id1623997153" />;
const Portfolio_image7 = <img src="/assets/images/portfolio/LoveVUE.png" alt="https://apps.apple.com/us/app/lovevue-couple-relationships/id1546605443" />;
const Portfolio_image8 = <img src="/assets/images/portfolio/Errand.png" alt="https://appadvice.com/app/errand-app-for-customers-only/1491509620" />;
const Portfolio_image9 = <img src="/assets/images/portfolio/ErrandDriverApp.png" alt="https://appadvice.com/app/errand-app-for-drivers-only/1491509730" />;
const Portfolio_image10 = <img src="/assets/images/portfolio/GlobalPeaceGroup.png" alt="https://apps.apple.com/us/app/global-peace-group/id1607544463" />;
const Portfolio_image11 = <img src="/assets/images/portfolio/SurviveAI.png" alt="https://apps.apple.com/us/app/surviveai-off-grid-survival/id6740878581" />;

const PortfolioListContent = [
    {
        image: Portfolio_image11,
        category: 'Mobile App Development',
        title: 'SurviveAI: Off-Grid Survival',
        url:"https://apps.apple.com/us/app/surviveai-off-grid-survival/id6740878581",
        description: 'On-Device AI for Emergencies'
    },
    {
        image: Portfolio_image,
        category: 'Mobile App Development',
        title: 'Healthwire: Medicine & Doctors',
        url:"https://apps.apple.com/pk/app/healthwire-medicine-doctors/id1460789804",
        description: 'For all your healthcare needs'
    },
    {
        image: Portfolio_image2,
        category: 'Mobile App Development',
        title: 'Chat pdf: Askpdf ChatPDF',
        url:"https://apps.apple.com/us/app/chat-pdf-askpdf-chatpdf/id6462723046",
        description: 'Ask your documents AI Chat Bot'
    },
    {
        image: Portfolio_image3,
        category: 'Mobile App Development',
        title: 'HCloud - For Doctors',
        url:"https://apps.apple.com/pk/app/hcloud-for-doctors/id1483037323",
        description: 'App for Healthwire Doctors appointments'
    },
    {
        image: Portfolio_image4,
        category: 'Mobile App Development',
        title: 'Blueberry - Business Payments',
        url:"https://apps.apple.com/us/app/blueberry-business-payments/id1568228823",
        description: 'POS, Invoices, & Appointments'
    },
    {
        image: Portfolio_image5,
        category: 'Mobile App Development',
        title: 'DogHood - Dog Care Platform',
        url:"https://apps.apple.com/us/app/thedoghood/id1547568850",
        description: 'Pet Network: Training Walking'
    },
    {
        image: Portfolio_image6,
        category: 'Mobile App Development',
        title: 'SoldLive Overlays',
        url:"https://apps.apple.com/za/app/soldlive-overlays/id1623997153",
        description: '#1 Shopify app for FB/IG Live Sales'
    },
    {
        image: Portfolio_image7,
        category: 'Mobile App Development',
        title: 'LoveVue: Couple Relationships',
        url:"https://apps.apple.com/us/app/lovevue-couple-relationships/id1546605443",
        description: ''
    },
    {
        image: Portfolio_image8,
        category: 'Mobile App Development',
        title: 'Errand App',
        url:"https://appadvice.com/app/errand-app-for-customers-only/1491509620",
        description: 'Provides fast, reliable transportation in minutes'
    },
    {
        image: Portfolio_image9,
        category: 'Mobile App Development',
        title: 'Errand - Drivers App',
        url:"https://appadvice.com/app/errand-app-for-drivers-only/1491509730",
        description: 'Provides fast, reliable transportation in minutes'
    },
    {
        image: Portfolio_image10,
        category: 'Mobile App Development',
        title: 'Global Peace Group',
        url:"https://apps.apple.com/us/app/global-peace-group/id1607544463",
        description: 'Heal Yourself and Heal the Planet through group collective prayer'
    }
]

class PortfolioList extends Component{
    render() {
        const { column, styevariation } = this.props;
        const list = PortfolioListContent.slice(0, this.props.item);

        return (
            <React.Fragment>
                {list.map((value, index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`im_portfolio ${styevariation}`}>
                            <div className="thumbnail_inner">
                                <div className="thumbnail">
                                    {/* External link with image */}
                                    <a href={value.url} target="_blank" rel="noopener noreferrer">
                                        {value.image}
                                    </a>
                                </div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <div className="portfolio_heading">
                                        <div className="category_list">
                                            <span>{value.category}</span>
                                        </div>
                                        <h4 className="title">
                                            {/* External link with title */}
                                            <a href={value.url} target="_blank" rel="noopener noreferrer">
                                                {value.title}
                                            </a>
                                        </h4>
                                    </div>
                                    <div className="portfolio_hover">
                                        <p>{value.description}</p>
                                    </div>
                                </div>
                            </div>
                            {/* Unnecessary Link components removed */}
                            <a href={value.url} target="_blank" rel="noopener noreferrer" className="transparent_link"></a>
                        </div>
                    </div>
                ))}
            </React.Fragment>
        );
    }
}
export default PortfolioList;